import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfigProduction = {
  apiKey: 'AIzaSyDtg4PxO7H5InqPZtmvoBBrFTyIZRa7dzo',
  authDomain: 'tpenso-production.firebaseapp.com',
  databaseURL: 'https://tpenso-production.firebaseio.com',
  projectId: 'tpenso-production',
  storageBucket: 'tpenso-production.appspot.com',
  messagingSenderId: '588008683831',
  appId: '1:588008683831:web:2f7357c81091d52ecb02b2',
}

class App {
  app: firebase.app.App

  constructor() {
    this.app = firebase.initializeApp(firebaseConfigProduction)
  }
}

let app: App | undefined = undefined

if (!app) {
  app = new App()
}

export default app as App
