import React from 'react'
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core'
import firebaseApp from '../firebase'

const NavBar: React.FC<{}> = () => (
  <AppBar position="static">
    <Toolbar>
      <Typography variant="h6">TPenso Admin</Typography>
      <Button color="inherit" css={{ marginLeft: 'auto' }} onClick={logout}>
        Logout
      </Button>
    </Toolbar>
  </AppBar>
)

function logout() {
  firebaseApp.app.auth().signOut()
}

export default NavBar
