import React from 'react'
import { format } from 'date-fns'
import { Table, TableContainer, TableHead, Paper, TableRow, TableCell, TableBody, Typography } from '@material-ui/core'
import firebaseApp from '../firebase'
import Loading from './loading'
import ExcelExportDownload from './excel-export-download'
import { firestore } from 'firebase'

interface SubscriptionData {
  title: string
  firstName: string
  lastName: string
  role: string
  mobile: string
  email: string
  center: string
  ward: string
  centerStreet: string
  centerCity: string
  centerProvince: string
  centerCap: string
  preferredMethodOfContact: 'email' | 'phone'
  welcomeCall: 'yes' | 'no'
  welcomeCallTimePreference?: string
  preferredDeliveryMethod: 'center' | 'patient'
  homeTraining: 'yes' | 'no'
  confirmIdentity: boolean
  requestActivation: boolean
  letterEConsent: 'given' | 'notGiven'
  letterFConsent: 'given' | 'notGiven'
  pspId: string
  createdAt: firestore.Timestamp
  senderIp: string
}

export interface Subscription extends SubscriptionData {
  id: string
  psp?: Psp
}

interface PspData {
  email: string
  name: string
  emailTemplate: string
}

interface Psp extends PspData {
  id: string
}

const SubscriptionTable: React.FC<{}> = () => {
  const [subscriptions, setSubscriptions] = React.useState<Subscription[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    firebaseApp.app
      .firestore()
      .collection('psps')
      .get()
      .then((psp) => {
        const psps: Psp[] = psp.docs.map((p) => ({ ...(p.data() as PspData), id: p.id }))
        firebaseApp.app
          .firestore()
          .collection('subscriptions')
          .orderBy('createdAt', 'desc')
          .get()
          .then((subs) => {
            const subscpts = subs.docs.map((s) => {
              const subData = s.data() as SubscriptionData
              const subPsp = psps.find((p) => p.id === subData.pspId)
              return {
                ...subData,
                id: s.id,
                psp: subPsp,
              }
            })
            setSubscriptions(subscpts)
            setLoading(false)
          })
      })
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" css={{ marginBottom: 16 }}>
          Elenco Adesioni
        </Typography>
        <ExcelExportDownload subscriptions={subscriptions} />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">Data</TableCell>
              <TableCell align="right">Nome</TableCell>
              <TableCell align="right">Ruolo</TableCell>
              <TableCell align="right">Centro</TableCell>
              <TableCell align="right">Reparto</TableCell>
              <TableCell align="right">Cellulare</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Welcome Call</TableCell>
              <TableCell align="right">Orario Contatto</TableCell>
              <TableCell align="right">Invio Autoiniettore</TableCell>
              <TableCell align="right">Training Domiciliare</TableCell>

              <TableCell align="right">Provincia</TableCell>
              <TableCell align="right">Città</TableCell>
              <TableCell align="right">Cap</TableCell>
              <TableCell align="right">Via</TableCell>
              <TableCell align="right">Consenso E</TableCell>
              <TableCell align="right">Consenso F</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptions.map((sub) => (
              <TableRow key={sub.id}>
                <TableCell align="right">{format(sub.createdAt.toDate(), 'dd/MM/Y HH:mm')}</TableCell>
                <TableCell align="right">{`${sub.title} ${sub.lastName} ${sub.firstName}`}</TableCell>
                <TableCell align="right">{sub.role}</TableCell>
                <TableCell align="right">{sub.center}</TableCell>
                <TableCell align="right">{sub.ward}</TableCell>
                <TableCell align="right">{sub.mobile}</TableCell>
                <TableCell align="right">{sub.email}</TableCell>
                <TableCell align="right">{sub.welcomeCall === 'yes' ? 'Si' : 'No'}</TableCell>
                <TableCell align="right">{sub.welcomeCallTimePreference}</TableCell>
                <TableCell align="right">{sub.preferredDeliveryMethod === 'center' ? 'Centro' : 'Paziente'}</TableCell>
                <TableCell align="right">{sub.homeTraining === 'yes' ? 'Si' : 'No'}</TableCell>
                <TableCell align="right">{sub.centerProvince}</TableCell>
                <TableCell align="right">{sub.centerCity}</TableCell>
                <TableCell align="right">{sub.centerCap}</TableCell>
                <TableCell align="right">{sub.centerStreet}</TableCell>
                <TableCell align="right">{sub.letterEConsent === 'given' ? 'Acconsento' : 'Non Acconsento'}</TableCell>
                <TableCell align="right">{sub.letterFConsent === 'given' ? 'Acconsento' : 'Non Acconsento'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default SubscriptionTable
