import React from 'react'
import NavBar from './nav-bar'
import { Grid } from '@material-ui/core'
import SubscriptionTable from './subscription-table'

const AdminPage: React.FC<{}> = () => {
  return (
    <div>
      <NavBar />
      <div css={{ padding: 32 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SubscriptionTable />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default AdminPage
