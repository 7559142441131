import React from 'react'
import { Subscription } from './subscription-table'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { Button } from '@material-ui/core'
import { format } from 'date-fns'

interface Props {
  subscriptions: Subscription[]
}

const ExcelExportDownload: React.FC<Props> = ({ subscriptions }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileName = 'data'
  const fileExtension = '.xlsx'

  const exportToCSV = (subs: Subscription[]) => {
    const preparedData = prepareData(subs)
    const ws = XLSX.utils.json_to_sheet(preparedData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <Button variant="contained" color="primary" onClick={() => exportToCSV(subscriptions)}>
      Export
    </Button>
  )
}

function prepareData(subscriptions: Subscription[]) {
  return subscriptions.map((s) => ({
    Data: format(s.createdAt.toDate(), 'dd/MM/Y HH:mm'),
    Titolo: s.title,
    Nome: s.firstName,
    Cognome: s.lastName,
    Cellulare: s.mobile,
    Email: s.email,
    WelcomeCall: s.welcomeCall === 'yes' ? 'Si' : 'No',
    OrarioPreferito: s.welcomeCallTimePreference,
    InvioAutoiniettore: s.preferredDeliveryMethod === 'center' ? 'Centro' : 'Paziente',
    TrainingDomiciliare: s.homeTraining === 'yes' ? 'Si' : 'No',
    Centro: s.center,
    Reparto: s.ward,
    Provincia: s.centerProvince,
    Comune: s.centerCity,
    Via: s.centerStreet,
    CAP: s.centerCap,
    Ruolo: s.role,
    LetteraE: s.letterEConsent === 'given' ? 'Acconsento' : 'Non Acconsento',
    LetteraF: s.letterFConsent === 'given' ? 'Acconsento' : 'Non Acconsento',
  }))
}

export default ExcelExportDownload
